.www {
	animation-duration: 400s;
	animation-iteration-count: infinite;
	animation-name: MOVEBG;
	animation-timing-function: linear;
	background-color: $color-4;
	background-image: url('../images/cloud.png');
	background-position: 100% -40%;
	background-repeat: no-repeat;
	background-size: 200%;
	color: $color-1;
	display: none;
	font-family: 'Averta CY', sans-serif;
	margin: 0 auto;
	max-width: 1200px;
	padding: 20px 20px 0;

	&-header {
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		text-align: center;

		&-logo {
			display: block;
			margin: 120px auto 100px;
			order: 0;
			width: 150px;
		}
	
		&-title {
			color: $color-1;
			display: block;
			font-family: 'Averta CY', sans-serif;
			font-size: 38px;
			font-weight: 600;
			line-height: 45px;
			margin-bottom: 117px;
			order: 2;
			width: 100%;
	
			&-light {
				color: $color-2;
			}
		}

		&-subtitle {
			color: $color-6;
			display: block;
			font-family: 'Averta CY', sans-serif;
			font-size: 20px;
			font-weight: 600;
			line-height: 23px;
			margin-bottom: 10px;
			order: 1;
			width: 100%;
		}
	}

	&-bar {
		color: $color-5;
		display: block;
		font-family: 'Averta CY', sans-serif;
		font-size: 18px;
		font-weight: 600;
		line-height: 25px;
		text-align: center;

		&-light {
			color: $color-1;
		}

		&-config {
			display: flex;
			justify-content: space-between;

			&-distance,
			&-temp {
				color: $color-1;
				display: inline-block;
		
				&-switch {
					cursor: pointer;
		
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&-list {
		display: block;
		font-family: 'Averta CY', sans-serif;
		font-size: 16px;
		font-weight: 400;
		width: 100%;

		&-item {
			background-color: $color-3;
			border-radius: 5px;
			display: grid;
			grid-template-columns: 50% 50%;
			margin: 15px 0;
			overflow: hidden;
			padding: 22px;
			position: relative;
			row-gap: 30px;

			&::before {
				background-image: url('../images/wind.png');
				background-repeat: no-repeat;
				content: '';
				height: 100%;
				mix-blend-mode: exclusion;
				position: absolute;
				width: 100%;
				z-index: 0;
			}

			div {
				z-index: 1;
			}

			&-weather {
				&-1000 { // Sunny, Clear 
					//
				}
				
				&-1003, // Partly cloudy  
				&-1006, // Cloudy  
				&-1009 { // Overcast
					&::before {
						background-image: url('../images/cloudy.png');
					}
				}

				&-1030 { // Mist
					&::before {
						background-image: url('../images/mist.png');
					}
				}

				&-1069, // Patchy sleet possible  
				&-1204, // Light sleet  
				&-1207, // Moderate or heavy sleet
				&-1210, // Patchy light snow
				&-1213, // Light snow
				&-1216, // Patchy moderate snow
				&-1219, // Moderate snow
				&-1222, // Patchy heavy snow
				&-1225, // Heavy snow
				&-1237, // Ice pellets
				&-1249, // Light sleet showers
				&-1252, // Moderate or heavy sleet showers
				&-1255, // Light snow showers
				&-1258, // Moderate or heavy snow showers
				&-1261, // Light showers of ice pellets
				&-1264, // Moderate or heavy showers of ice pellets
				&-1066 { // Patchy snow possible
					&::before {
						background-image: url('../images/snow.png');
					}
				}

				&-1117, // Blizzard
				&-1114 { // Blowing snow
					&::before {
						background-image: url('../images/snowstorm.png');
					}
				}

				&-1273, // Patchy light rain with thunder
				&-1276, // Moderate or heavy rain with thunder
				&-1279, // Patchy light snow with thunder
				&-1282, // Moderate or heavy snow with thunder
				&-1087 { // Thundery outbreaks possible
					&::before {
						background-image: url('../images/thunder_lightning.png');
					}
				}

				&-1147, // Freezing fog
				&-1135 { // Fog
					&::before {
						background-image: url('../images/fog.png');
					}
				}

				&-1240, // Light rain shower
				&-1198, // Light freezing rain
				&-1183, // Light rain
				&-1180, // Patchy light rain
				&-1168, // Freezing drizzle
				&-1072, // Patchy freezing drizzle possible
				&-1150, // Patchy light drizzle
				&-1153, // Light drizzle
				&-1063 { // Patchy rain possible
					&::before {
						background-image: url('../images/rain.png');
					}
				}

				&-1246, // Torrential rain shower
				&-1243, // Moderate or heavy rain shower
				&-1201, // Moderate or heavy freezing rain
				&-1195, // Heavy rain
				&-1192, // Heavy rain at times
				&-1189, // Moderate rain
				&-1186, // Moderate rain at times
				&-1171 { // Heavy freezing drizzle
					&::before {
						background-image: url('../images/heavy_rain.png');
					}
				}
			}

			&-city {
				grid-column-end: 3;
				grid-column-start: 1;
				grid-row-end: 2;
				grid-row-start: 1;
				line-height: 30px;

				.www-list-item-value {
					&-location {
						display: block;
						font-size: 24px;
						line-height: 26px;
					}

					&-weather {
						display: block;
						font-size: 20px;
					}
				}
			}

			&-localtime {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 3;
				grid-row-start: 2;

				.www-list-item-value span {
					align-items: center;
					display: flex;

					img {
						height: 21px;
						margin-right: 6px;
					}
				}
			}

			&-temperature {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-wind {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 5;
				grid-row-start: 4;
			}

			&-visibility {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-precipitation {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 5;
				grid-row-start: 4;
			}

			&-airport {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 3;
				grid-row-start: 2;
				position: relative;
				z-index: 2 !important;

				.www-list-item {
					&-value {
						background: $color-4;
						border-radius: 13px;
						color: $color-5;
						cursor: pointer;
						font-size: 18px;
						line-height: 22px;
						padding: 3px 15px;
						width: fit-content;

						&:hover {
							& ~ .www-list-item-metar {
								background: $color-4;
								border-radius: 5px;
								display: block;
								left: -7px;
								line-height: 20px;
								opacity: 0.9;
								padding: 7px;
								position: absolute;
								top: -5px;
								width: max-content;
							}
						}
					}

					&-metar {
						display: none;

						&:hover {
							background: $color-4;
							border-radius: 5px;
							display: block;
							left: -7px;
							line-height: 20px;
							opacity: 0.9;
							padding: 7px;
							position: absolute;
							top: -5px;
							width: max-content;
						}
					}
				}
			}

			&-category {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 6;
				grid-row-start: 5;
			}

			&-alert {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 6;
				grid-row-start: 5;

				.www-list-item-value {
					color: $color-5;
				}
			}

			&-score {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 6;
				grid-row-start: 5;

				.www-list-item-value {
					div {
						display: inline-block;
						height: 20px;
						margin-right: 5px;
						width: 5px;
					}
					
					.www-list-item-score-block-active {
						background-color: $color-5;
					}

					.www-list-item-score-block-inactive {
						background-color: $color-5-20;
					}
				}
			}

			&-parameter {
				color: $color-2;
				margin-bottom: 6px;
			}

			&-value {
				color: $color-1;
				line-height: 20px;
			}
		}
	}

	&-cookies {
		background-color: $color-6;
		border-radius: 10px;
		bottom: 20px;
		color: $color-1;
		font-size: 15px;
		left: 40px;
		line-height: 18px;
		padding: 20px;
		position: fixed;
		right: 40px;
		z-index: 2;

		&-button {
			background: $color-6;
			border: solid 1px $color-1;
			border-radius: 20px;
			color: $color-1;
			font-weight: 600;
			margin-top: 36px;
			padding: 12px 34px;
			text-transform: uppercase;
		}
	}

	footer {
		color: $color-2;
		font-size: 13px;
		line-height: 25px;
		margin: 50px;
		text-align: center;

		a {
			color: $color-1;
			text-decoration: none;
		}
	}
}
