$color-1: #f7f7f7;
$color-2: #a0a0a2;
$color-3: #212c34;
$color-4: #131e26;
$color-5: #03bdaf;
$color-5-20: rgba(#03bdaf, 0.2);
$color-6: #d9307c;

$color-white: #fff;

$www-med: 768px;
$www-lrg: 1200px;
$www-xlg: 1600px;
