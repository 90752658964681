.www {
	padding: 40px 45px 0;

	&-header {
		padding: 0;
		text-align: left;

		&-logo {
			display: inline-block;
			margin: 0 0 150px;
			width: 150px;
		}

		&-subtitle {
			font-size: 25px;
			justify-content: space-between;
			line-height: 28px;
			text-align: left;
		}

		&-title {
			font-size: 60px;
			line-height: 69px;
		}
	}

	&-bar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
		position: relative;

		&-region {
			display: flex;

			p {
				margin-right: 10px;
			}
		}

		&-config {
			bottom: 0;
			flex-direction: column;
			position: absolute;
			right: 0;
		}
	}

	&-list {
		&-item {
			grid-template-columns: 25% 25% 25% 25%;

			&::before {
				bottom: -200%;
				height: 200%;
				transform: rotate(-90deg);
				transform-origin: top left;
			}

			&-weather {
				&-1240, // Light rain shower
				&-1198, // Light freezing rain
				&-1183, // Light rain
				&-1180, // Patchy light rain
				&-1168, // Freezing drizzle
				&-1072, // Patchy freezing drizzle possible
				&-1150, // Patchy light drizzle
				&-1153, // Light drizzle
				&-1063 { // Patchy rain possible
					&::before {
						bottom: auto;
						height: 100%;
						transform: none;
					}
				}

				&-1246, // Torrential rain shower
				&-1243, // Moderate or heavy rain shower
				&-1201, // Moderate or heavy freezing rain
				&-1195, // Heavy rain
				&-1192, // Heavy rain at times
				&-1189, // Moderate rain
				&-1186, // Moderate rain at times
				&-1171 { // Heavy freezing drizzle
					&::before {
						bottom: auto;
						height: 100%;
						transform: none;
					}
				}
			}

			&-city {
				grid-column-end: 5;
				grid-column-start: 1;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-airport {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-category {
				grid-column-end: 4;
				grid-column-start: 3;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-localtime {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-temperature {
				grid-column-end: 4;
				grid-column-start: 3;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-visibility {
				grid-column-end: 5;
				grid-column-start: 4;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-wind {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-precipitation {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-alert {
				grid-column-end: 4;
				grid-column-start: 3;
				grid-row-end: 4;
				grid-row-start: 3;
			}

			&-score {
				grid-column-end: 5;
				grid-column-start: 4;
				grid-row-end: 4;
				grid-row-start: 3;
			}
		}
	}

	footer {
		font-size: 15px;
	}
}
