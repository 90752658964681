.www {
	padding: 60px 100px 0;

	&-list {
		&-item {
			grid-template-columns: 30% 14% 14% 14% 14% 14%;
			padding: 30px;

			&-city {
				grid-column-end: 2;
				grid-column-start: 1;
				grid-row-end: 2;
				grid-row-start: 1;
				padding-right: 25px;

				span {
					font-size: 24px;
				}
			}

			&-localtime {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-temperature {
				grid-column-end: 4;
				grid-column-start: 3;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-wind {
				grid-column-end: 5;
				grid-column-start: 4;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-visibility {
				grid-column-end: 6;
				grid-column-start: 5;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-precipitation {
				grid-column-end: 7;
				grid-column-start: 6;
				grid-row-end: 2;
				grid-row-start: 1;
			}

			&-airport {
				grid-column-end: 3;
				grid-column-start: 2;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-category {
				grid-column-end: 4;
				grid-column-start: 3;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-alert {
				grid-column-end: 6;
				grid-column-start: 4;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-score {
				grid-column-end: 7;
				grid-column-start: 6;
				grid-row-end: 3;
				grid-row-start: 2;
			}

			&-parameter {
				color: $color-2;
				margin-bottom: 6px;
			}

			&-value {
				color: $color-1;
			}
		}
	}

	&-cookies {
		left: auto;
		width: 300px;

		&-button {
			cursor: pointer;

			&:hover {
				background-color: $color-4;
			}
		}
	}
}
