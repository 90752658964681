@font-face {
	font-family: 'Handlee';
	src: url('../fonts/Handlee-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */;
}

@font-face {
	font-family: 'open-sans';
	src: url('../fonts/OpenSans-Regular.ttf') format('truetype'),
		/* Safari, Android, iOS */;
}

@font-face {
	font-display: swap;
	font-family: 'Averta CY';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/AvertaCY-Regular.woff2') format('woff2'),
		url('../fonts/AvertaCY-Regular.woff') format('woff'),
		url('../fonts/AvertaCY-Regular.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Averta CY';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/AvertaCY-Semibold.woff2') format('woff2'),
		url('../fonts/AvertaCY-Semibold.woff') format('woff'),
		url('../fonts/AvertaCY-Semibold.ttf') format('truetype');
}
