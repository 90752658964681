@keyframes MOVEBG {
	0% {
		background-position-x: 100%;
	}

	50% {
		background-position-x: 0%;
	}
	
	100% { 
		background-position-x: 100%;
	}
}